$font-size: calc(0.8rem + 0.25vw) !default
$font-weight: 400 !default
$heading-weight: 400 !default
$line-height: 1.65 !default
$link-color: #68f !default
$dark: #1a1f1d !default
$light: #ffffff !default

@function reduce ($percent)
  @return rgba(mix($dark, $light), calc($percent / 100))

@import basic, highlight, layout, darkmode

.light
  background: $light
  color: $dark

.dark
  background: $dark
  color: $light
