body
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", sans-serif
  font-size: 16px
  font-size: $font-size
  font-weight: $font-weight
  line-height: $line-height
  -webkit-text-size-adjust: 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

header
  font-family: "Mulish",Helvetica Neue,Helvetica,Arial,sans-serif

ul.archive > li
  list-style: none

ul.archive
  margin-left: -20px

body, blockquote, figure
  margin: 0

h1, h2, h3, h4, h5, h6
  font-weight: $heading-weight

b, strong, th
  font-weight: $font-weight + 200

a
  color: inherit
  text-decoration: inherit
  transition: color .2s
  &.selected, &:hover
    color: reduce(100)

article a, footer a
  color: $link-color

/*article header a, article footer a
  color: inherit */

header time
  color: reduce(100)

hr
  border: 1px solid reduce(30)
  margin: 2em 0

blockquote
  background: reduce(6)
  border-left: 4px solid #68f
  padding: 1px 1.5em

img
  border-radius: 2px
  max-width: 100%
  height: auto
  margin: .5em 0

table
  box-shadow: 0 0 0 1px reduce(12) inset
  border-spacing: 1px
  width: 100%
  overflow-x: auto

tr:hover, tr:nth-child(odd) td
  background: reduce(6)

th, td
  box-shadow: 0 0 0 1px reduce(12)
  padding: .5em 1em

pre
  background: rgba(34, 34, 34, 0.8)
  color: white
  border-radius: 2px
  font-size: .8em
  margin: 1.5em 0
  padding: .8em 1.2em
  overflow-x: auto

p code
  font-size: .9em
  background: reduce(15)
  opacity: .75
  border-radius: 2px
  margin: 0 .1em
  padding: .2em .4em

.caption
  font-size: .9rem
  font-style: italic

.image-container .caption
  display: block