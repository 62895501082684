@import index

body > header, article, body > footer
  padding: 1.5em

@media (min-width: 40em)
  body > header, article, body > footer
    padding: 1.5em calc(34% - 12rem)



body > header
  background: #2d3534
  color: #fff
  border-bottom: 1px solid rgba(255, 255, 255, .05)




