body > header, body > footer
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center

header h1, header h2
  margin: 0

nav
  margin: .5em -.8em

nav a
  padding: 0 .8em
